import { graphql } from 'gatsby'
import React from 'react'

// import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import { IndexQueryQuery } from '../../types/graphql-types'
//import Post from '../templates/post/post'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <div className="section minHeight shape1-12 text-center d-flex align-items-center container-fluid">
        <div className="container-xl py-5">
          <h2>Welcome to Sodality Ventures</h2>
          <p className="container-md font-size-lg">
            We’d love to help you build an impactful company, whose destiny you
            control, which reflects your values, has the freedom to grow at the
            pace which is right for your business, and is fueled by happy
            customers and a sustainable team culture.
          </p>
          <br />
          <a
            className="btn btn-lg btn-secondary"
            href="/learn-more/"
            role="button"
          >
            Learn More
          </a>
        </div>
      </div>
      <div className="section minHeight bg4 d-flex text-center align-items-center bg-dark text-light container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs">
            <p className="font-size-lg">
              <b>
                Sodality (/sōˈdalədē/) <i>noun</i>
              </b>{' '}
              a generative social space for agency, imagination, and social
              action
            </p>
          </div>
        </div>
      </div>
      <div className="section minHeight shape2 d-flex text-center align-items-center">
        <div className="container-lg py-10">
          <p className="display3-size">
            Founding a company <strong>doesn’t have to mean</strong> swinging
            for a <strong>billion dollar valuation or bust</strong>.
          </p>
        </div>
      </div>
      <div className="section minHeight bg3 shape9 d-flex align-items-center bg-dark text-light container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs m-0">
            <h2>We should chat</h2>
            <p>
              Before you hop on the Venture Capital treadmill and find yourself
              focused on “fundable milestones” rather than your customers and
              your team, we should chat.
            </p>
            <div>
              <a
                className="btn btn-lg btn-primary"
                href="/work-with-us/"
                role="button"
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`

// import { graphql } from 'gatsby'
// import React from 'react'

// import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
// import Post from '../templates/post/post'
// import Meta from '../components/meta/meta'
// import Layout from '../components/layout/layout'

// interface Props {
//   data: IndexQueryQuery
//   location: Location
// }

// const BlogIndex: React.FC<Props> = ({ data, location }: Props) => {
//   const posts = data.remark.posts
//   const meta = data.site?.meta

//   return (
//     <Layout location={location}>
//       <Meta site={meta} />
//       {posts.map((post, i) => (
//         <Post
//           data={post as PostByPathQuery}
//           options={{
//             isIndex: true,
//           }}
//           key={i}
//         />
//       ))}
//     </Layout>
//   )
// }

// export default BlogIndex

// export const pageQuery = graphql`
//   query IndexQuery {
//     site {
//       meta: siteMetadata {
//         title
//         description
//         siteUrl
//         author
//         twitter
//         adsense
//       }
//     }
//     remark: allMarkdownRemark(
//       sort: { fields: [frontmatter___date], order: DESC }
//     ) {
//       posts: edges {
//         post: node {
//           html
//           frontmatter {
//             layout
//             title
//             path
//             category
//             tags
//             description
//             date(formatString: "YYYY/MM/DD")
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 500) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
